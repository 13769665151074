import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import getUserLanguage from "../../../utils/Portfolio/languageSelector"; // Import the language detection utility
import { skills, skillsTitle } from "../../../utils/Portfolio/skillsList"; // Import updated skills

// SkillSection Component
const SkillSection = ({ title, tools, icon }) => (
  <motion.div
    className="text-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.2 }}
  >
    <div className="flex items-center justify-center w-20 h-20 mb-3 mx-auto">
      <i
        className={icon}
        aria-hidden="true"
        style={{
          fontSize: "4rem",
          color: "#3B82F6",
        }}
      ></i>
    </div>
    <h6
      className="mb-3 font-semibold leading-5 border-b-2 pb-1"
      style={{
        fontFamily: "Poppins, sans-serif",
        color: "#111828",
        fontSize: "1.125rem",
        fontWeight: "600",
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
      }}
    >
      {title}
    </h6>
    <div className="flex flex-wrap justify-center gap-1">
      {tools.map((tool, index) => (
        <a
          key={index}
          href={`https://img.shields.io/badge/${encodeURIComponent(
            tool.name
          )}-${tool.color}?style=flat&logo=${tool.logo}&logoColor=white`}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block"
        >
          <img
            src={`https://img.shields.io/badge/${encodeURIComponent(
              tool.name
            )}-${tool.color}?style=flat&logo=${tool.logo}&logoColor=white`}
            alt={tool.name}
            className="mb-1"
            style={{ maxWidth: "100px" }}
          />
        </a>
      ))}
    </div>
  </motion.div>
);

const Skills = () => {
  const language = getUserLanguage(); // Get user's language

  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.05,
  });

  const { ref: skillsRef, inView: skillsInView } = useInView({
    triggerOnce: true,
    threshold: 0.05,
  });

  const skillSectionVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const containerVariants = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.2 } },
  };

  // Extract skill data based on the current language
  const skillTitles = skillsTitle[language];
  const {
    frontEndFrameworks,
    backEndFrameworks,
    programmingLanguages,
    databases,
    developmentTools,
    cloudAndHosting,
  } = skills.technologies;

  return (
    <div
      id="skills"
      className="relative px-4 py-16 mx-auto sm:px-6 md:px-12 lg:px-16 lg:py-20"
    >
      <div className="absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-gray-100 to-gray-200 opacity-90 shadow-md z-10"></div>
      <div
        className="relative max-w-5xl mx-auto pt-16 pb-12 lg:pt-24 lg:pb-20"
        ref={titleRef}
      >
        <motion.h2
          className="text-3xl sm:text-4xl lg:text-4xl font-bold leading-tight text-gray-900 text-center"
          style={{
            fontFamily: "Poppins, sans-serif",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: titleInView ? 1 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <span className="relative inline-block">
            <span className="relative" style={{ color: "#1E2A38" }}>
              {skillTitles.title}
              {/* Default text if title is not available */}
            </span>
          </span>
        </motion.h2>
      </div>

      <motion.div
        ref={skillsRef}
        className="grid gap-6 row-gap-8 lg:grid-cols-3 sm:grid-cols-2"
        initial="hidden"
        animate={skillsInView ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.programmingLanguages}
            tools={programmingLanguages}
            icon="text-blue-accent-700 text-3xl fa-solid fa-code"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.frontEndFrameworks}
            tools={frontEndFrameworks}
            icon="text-blue-accent-700 text-3xl fab fa-react"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.backEndFrameworks}
            tools={backEndFrameworks}
            icon="text-blue-accent-700 text-3xl fab fa-python"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.databases}
            tools={databases}
            icon="text-blue-accent-700 text-3xl fa-solid fa-database"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.developmentTools}
            tools={developmentTools}
            icon="text-blue-accent-700 text-2xl fa-solid fa-tools"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.cloudAndHosting}
            tools={cloudAndHosting}
            icon="text-blue-accent-700 text-3xl fa-solid fa-cloud"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Skills;
