// utils/aboutMeList.js
const aboutGodotList = {
  title: {
    en: "About The Game",
    pl: "O Grze",
  },
  texts: {
    en: [
      "I am actively developing a game called Santes, using the Godot engine, which has significantly improved my skills in GDScript and game development. During this project, I gained practical knowledge in debugging, optimization, and creating engaging experiences for players. While working on the Godot engine, I edited and optimized the engine’s C++ code, removing unnecessary elements, improving performance, and reducing the exported Android app size by 50%, resulting in smoother performance on mobile devices. I handled the compilation process using Gradle.",
      "Additionally, I successfully implemented AdMob ads, integrated Google Play payments for in-app purchases, and developed a system for storing data both locally and in the cloud. Throughout the integration process, I focused on securely storing user data and optimizing the app’s performance across different environments.",
    ],
    pl: [
      "Aktywnie rozwijam grę o nazwie Santes, korzystając z silnika Godot, co znacząco poprawiło moje umiejętności w GDScript i tworzeniu gier. W trakcie tego projektu zdobyłem praktyczną wiedzę na temat debugowania, optymalizacji i tworzenia angażujących doświadczeń dla graczy. Podczas pracy nad silnikiem Godot edytowałem i optymalizowałem kod w C++, usuwając zbędne elementy, poprawiając wydajność oraz zmniejszając wagę eksportowanej aplikacji na Androida o 50%, co przyczyniło się do lepszej płynności działania na urządzeniach mobilnych. Proces kompilacji realizowałem za pomocą Gradle.",
      "Dodatkowo, z powodzeniem zaimplementowałem reklamy AdMob, zintegrowałem płatności Google Play dla zakupów wewnątrz aplikacji oraz opracowałem system przechowywania danych zarówno lokalnie, jak i w chmurze. W trakcie procesu integracji skupiłem się na bezpiecznym przechowywaniu danych użytkowników oraz optymalizacji działania aplikacji w różnych środowiskach.",
    ],
  },
};

export default aboutGodotList;
