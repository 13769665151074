import React, { useState, useEffect } from "react";

const LoadingScreen = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const isPolish = navigator.language.startsWith("pl");
  const loadingText = isPolish ? "Ładowanie..." : "Loading...";

  useEffect(() => {
    const handleImageLoad = () => {
      setIsFadingOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const images = Array.from(document.images);
    const totalImages = images.length;
    let loadedImages = 0;

    if (totalImages === 0) {
      handleImageLoad();
    } else {
      images.forEach((image) => {
        if (image.complete) {
          loadedImages += 1;
          if (loadedImages === totalImages) {
            handleImageLoad();
          }
        } else {
          const onImageLoad = () => {
            loadedImages += 1;
            if (loadedImages === totalImages) {
              handleImageLoad();
            }
          };

          image.addEventListener("load", onImageLoad);
          image.addEventListener("error", onImageLoad);
        }
      });
    }

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", () => {});
        image.removeEventListener("error", () => {});
      });
    };
  }, []);

  const loadingScreenStyle = {
    position: "fixed",
    inset: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f7fafc",
    zIndex: 15,
    opacity: isFadingOut ? 0 : 1,
    transition: "opacity 1s ease-out",
    pointerEvents: isFadingOut ? "none" : "all",
  };

  const contentWrapperStyle = {
    opacity: loading ? 0 : 1,
    transition: "opacity 0.5s ease-in",
    pointerEvents: loading ? "none" : "all",
  };

  return (
    <>
      {loading && (
        <div style={loadingScreenStyle}>
          <div className="flex flex-col items-center text-center">
            <div className="relative flex items-center justify-center w-24 h-24">
              <div className="absolute w-24 h-24 border-4 border-t-4 border-transparent border-t-blue-600 rounded-full animate-spin"></div>
            </div>
            <h1 className="mt-6 text-3xl font-semibold text-gray-700">
              {loadingText}
            </h1>
          </div>
        </div>
      )}
      <div style={contentWrapperStyle}>{children}</div>
    </>
  );
};

export default LoadingScreen;
