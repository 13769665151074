import techBadges from "./techBadges";

// Define getSkills function before using it
const getSkills = (names) => {
  return techBadges
    .filter((badge) => names.includes(badge.name))
    .map((badge) => ({
      name: badge.name,
      color: badge.color,
      logo: badge.logo,
    }));
};

const skillsTitle = {
  // Removed the extra colon
  en: {
    title: "My Skills",
    programmingLanguages: "Programming Languages",
    frontEndFrameworks: "FrontEnd",
    backEndFrameworks: "BackEnd",
    databases: "Databases",
    developmentTools: "Tools",
    cloudAndHosting: "Cloud and Hosting",
  },
  pl: {
    title: "Moje Umiejętności",
    programmingLanguages: "Języki Programowania",
    frontEndFrameworks: "FrontEnd",
    backEndFrameworks: "BackEnd",
    databases: "Bazy Danych",
    developmentTools: "Narzędzia",
    cloudAndHosting: "Chmura",
  },
};

const skills = {
  technologies: {
    programmingLanguages: getSkills([
      "JavaScript",
      "Python",
      "C++",
      "Java",
      "Kotlin",
      "C#",
    ]),
    frontEndFrameworks: getSkills([
      "React",
      "NextJs",
      "TailwindCSS",
      "Qt",
      "APIs",
      "OAuth2",
      "HTML",
      "CSS",
    ]),
    backEndFrameworks: getSkills(["Django", "Flask", "NodeJS", "Express.js"]),
    databases: getSkills(["MySQL", "PostgreSQL", "SQLite"]),
    developmentTools: getSkills([
      "VSCode",
      "Git",
      "Linux",
      "Docker",
      "JetBrains",
      "PowerShell",
      "Android Studio",
      "Neovim",
      "Gradle",
      "VMware",
      "Jira",
      "Confluence",
    ]),
    cloudAndHosting: getSkills(["Vercel", "Firebase"]),
  },
};

export { skills, skillsTitle };
