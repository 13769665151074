const navbarList = {
  en: {
    aboutMe: "About Me",
    gameDevLink: "GameDev",
    skills: "Skills",
    projects: "Projects",
    experience: "Experience",
    contactMe: "Contact Me",
  },
  pl: {
    aboutMe: "O mnie",
    gameDevLink: "GameDev",
    skills: "Umiejętności",
    projects: "Projekty",
    experience: "Doświadczenie",
    contactMe: "Kontakt",
  },
};

export default navbarList;
