import React from "react";
import { FaArrowLeft } from "react-icons/fa"; // Import the left arrow icon from react-icons

const Title = ({ title, href }) => {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
        minHeight: "100px",
        position: "relative", // Ensure the button can be positioned on the left
      }}
    >
      <button
        onClick={() => (window.location.href = href || "/")} // Use href prop, default to "/"
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)", // Vertically center the icon
          background: "none",
          border: "none",
          color: "#fff",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        <FaArrowLeft style={{ marginLeft: "8px" }} />{" "}
        {/* Add margin to the left of the icon */}
      </button>
      <h2
        className="text-4xl font-bold text-white"
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "800",
          borderBottom: "4px solid #2979ff",
        }}
      >
        {title}
      </h2>
    </div>
  );
};

export default Title;
