// utils/certificatesList.js
import englishCert from "../../../src/img/englishC1.webp";
import dataScienceCert from "../../../src/img/data_science.webp";
import kotlinCert from "../../../src/img/kotlin.webp";
import pythonCert from "../../../src/img/python.webp";

const certificatesList = {
  title: {
    en: "Certificates",
    pl: "Certyfikaty",
  },
  certificates: {
    en: [
      {
        title: "Google IT Automation with Python",
        imgSrc: pythonCert,
      },
      {
        title: "Kotlin for Java Developers",
        imgSrc: kotlinCert,
      },
      {
        title: "IBM Data Science",
        imgSrc: dataScienceCert,
      },
      {
        title: "English C1",
        imgSrc: englishCert,
      },
    ],
    pl: [
      {
        title: "Automatyzacja IT Google z Pythonem",
        imgSrc: pythonCert,
      },
      {
        title: "Kotlin dla programistów Java",
        imgSrc: kotlinCert,
      },
      {
        title: "IBM Data Science",
        imgSrc: dataScienceCert,
      },
      {
        title: "C1 z języka angielskiego",
        imgSrc: englishCert,
      },
    ],
  },
};

export default certificatesList;
