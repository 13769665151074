// utils/experienceList.js
import swierkImage from "../../../src/img/swierk.webp"; // Adjust path accordingly
import dataPigeonsImage from "../../../src/img/data_pigeons.webp"; // Adjust path accordingly

const additionalExperienceList = {
  title: {
    en: "Additional Experience",
    pl: "Dodatkowe Doświadczenie",
    // Add more languages here
  },
  experiences: {
    en: [
      {
        title: "Swierk 2.0",
        date: "Since October 10, 2024, until now",
        description: `I lead the 'Swierk 2.0' college project, overseeing a team of 15 members and ensuring tasks are completed on schedule and meet high standards. I organize and assign tasks in Jira, breaking them down into user stories while monitoring the team’s progress. Additionally, I manage project documentation on Confluence, maintaining a structured and accessible environment. The frontend of the application is developed with Next.js, and I collaborate closely with the team to ensure smooth integration between the frontend and backend, where I contribute to backend development using Python. I also utilize WSL and Docker to optimize the development and deployment workflows, with the help of MSc Eng. Izabela Kartowicz Stolarska, who provides valuable guidance and support throughout the project.`,
        image: swierkImage,
      },
      {
        title: "Data Pigeons Student Group",
        date: "From December 6, 2023, to November 2024",
        description: `I founded the Data Pigeons student group at the Bialystok University of Technology's Faculty of Computer Science. Alongside my primary collaborator, I organized our activities with valuable support from PhD Eng. Magdalena Topczewska. Our group consisted of more than 20 students who collaborated on data science and machine learning projects. We held weekly meetings on Wednesdays via Microsoft Teams throughout the academic year and occasionally visited high schools to showcase our projects. Our work involved analyzing various datasets, including the Iris dataset, and visualizing results using Python, R, and Julia. The group continues its operations, involving new members and expanding its project portfolio.`,
        image: dataPigeonsImage,
      },
    ],
    pl: [
      {
        title: "Swierk 2.0",
        date: "Od 10 października 2024 do teraz",
        description: `Kieruję projektem 'Swierk 2.0' na uczelni, zarządzając zespołem 15 osób i zapewniając terminowe wykonanie zadań na wysokim poziomie. Organizuję i przypisuję zadania w Jira, dzieląc je na historie użytkowników, a także monitoruję postęp pracy zespołu. Dodatkowo zarządzam dokumentacją projektu na Confluence, utrzymując porządek i dostępność materiałów. Frontend aplikacji jest tworzony przy użyciu Next.js, a ja ściśle współpracuję z zespołem, dbając o płynne połączenie frontendowej i backendowej części projektu, w którym pracuję nad rozwojem backendu w Pythonie. Korzystam również z WSL i Dockera, aby zoptymalizować procesy rozwoju i wdrożenia, przy wsparciu mgr inż. Izabeli Kartowicz Stolarskiej, która udziela cennych wskazówek i wsparcia w trakcie realizacji projektu.`,
        image: swierkImage,
      },
      {
        title: "Grupa Studentów Data Pigeons",
        date: "Od 6 grudnia 2023 do listopada 2024",
        description: `Założyłem grupę studencką Data Pigeons na Wydziale Informatyki Politechniki Białostockiej. Wraz z moim głównym współpracownikiem zajmowaliśmy się organizacją naszych działań, korzystając z cennego wsparcia dr inż. Magdaleny Topczewskiej. Nasza grupa składała się z ponad 20 studentów, którzy współpracowali przy projektach z zakresu data science i uczenia maszynowego. Organizowaliśmy cotygodniowe spotkania w środy za pośrednictwem Microsoft Teams przez cały rok akademicki i od czasu do czasu odwiedzaliśmy szkoły średnie, aby zaprezentować nasze projekty. Nasza praca obejmowała analizowanie różnych zbiorów danych, w tym zbioru Iris, oraz wizualizowanie wyników przy użyciu Pythona, R i Julii. Koło nadal działa, angażując nowych członków i poszerzając portfolio projektów.`,
        image: dataPigeonsImage,
      },
    ],
    // Add more languages here
  },
};

export default additionalExperienceList;
