import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import fight from "../../../img/santes/fight.webp";
import secure from "../../../img/santes/secure.webp";
import survive from "../../../img/santes/survive.webp";
import dodge from "../../../img/santes/dodge.webp";
import weapons from "../../../img/santes/weapons.webp";
import armor from "../../../img/santes/armor.webp";
import team from "../../../img/santes/team.webp";
import ability from "../../../img/santes/ability.webp";

const GameScreenshots = () => {
  // Intersection observer to detect when the section is in view
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const screenshots = [
    fight,
    secure,
    survive,
    dodge,
    weapons,
    armor,
    team,
    ability,
  ];

  const isPolish = navigator.language.startsWith("pl");
  const titleText = isPolish ? "Galeria" : "Gallery";

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1, slidesToSlide: 1 },
  };

  return (
    <motion.div
      ref={ref} // Attach ref for intersection observer
      id="gamescreenshots"
      className="relative flex flex-col items-center bg-white py-32" // Increased padding
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }} // Animate only when in view
      transition={{ duration: 0.8 }}
    >
      <div className="absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-gray-100 to-gray-200 opacity-90 shadow-md z-10 mb-16"></div>{" "}
      {/* Added mb-16 */}
      {/* Title */}
      <motion.div
        className="text-center mb-10" // Increased margin-bottom
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.6 }}
      >
        <h2
          className="text-4xl font-bold tracking-tight text-gray-900"
          style={{
            fontFamily: "Poppins, sans-serif", // Added Poppins font here
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Optional text shadow
          }}
        >
          {titleText}
        </h2>
      </motion.div>
      {/* Carousel */}
      <motion.div
        className="w-full px-4 lg:px-16 max-w-screen-xl"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="transform 300ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container relative"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {screenshots.map((image, index) => (
            <motion.div
              key={index}
              className="relative w-full"
              transition={{ duration: 0.3 }}
            >
              <motion.img
                src={image}
                alt={`Screenshot ${index + 1}`}
                className="w-full h-auto shadow-lg rounded-lg"
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }} // Animate only when in view
                transition={{ duration: 0.6, delay: index * 0.1 }}
              />
            </motion.div>
          ))}
        </Carousel>
      </motion.div>
    </motion.div>
  );
};

export default GameScreenshots;
