import React from "react";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const GameTrailer = () => {
  const isPolish = navigator.language.startsWith("pl");
  const titleText = isPolish ? "Zwiastun" : "Trailer";

  // Intersection observer for the whole section
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      id="trailer"
      className="relative flex flex-col items-center bg-white py-16"
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      transition={{ duration: 0.8 }}
    >
      {/* Title Animation */}
      <motion.div
        className="text-center mb-10"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.6 }}
      >
        <h2
          className="text-4xl font-bold tracking-tight text-gray-900"
          style={{
            fontFamily: "Poppins, sans-serif", // Added Poppins font here
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Optional text shadow
          }}
        >
          {titleText}
        </h2>
      </motion.div>

      {/* Video Animation */}
      <motion.div
        className="w-full px-4 lg:px-16 max-w-screen-xl"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <div className="relative pb-[56.25%]">
          <ReactPlayer
            url="https://youtu.be/FHmyEzQRWkk?feature=shared"
            width="100%"
            height="100%"
            controls={true}
            playing={false}
            className="absolute top-0 left-0 shadow-lg rounded-lg"
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default GameTrailer;
