// components/GameGodot.js
import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutUnityList from "../../../utils/GameDev/aboutUnityList"; // Import aboutMeList
import getUserLanguage from "../../../utils/Portfolio/languageSelector"; // Import your language selector
import Picture from "../../../img/unity.png"; // Import your image

const GameUnity = () => {
  // Intersection observer for title
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Intersection observer for image and description
  const { ref: contentRef, inView: contentInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage(); // Get the user's language
  const aboutUnityData = aboutUnityList;
  const title = aboutUnityData.title[language] || aboutUnityData.title.en;
  const texts = aboutUnityData.texts[language] || aboutUnityData.texts.en;

  return (
    <div
      id="aboutme"
      className="relative flex justify-center items-center py-16"
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
      }}
    >
      <div className="absolute top-0 left-0 w-full h-24 z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 283.5 19.6"
          preserveAspectRatio="none"
          className="w-full h-full opacity-33"
        >
          <path
            style={{ fill: "#60646f" }}
            d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"
          />
          <path
            style={{ fill: "#94979e" }}
            d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"
          />
          <path
            style={{ fill: "#b8b9be" }}
            d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"
          />
          <path
            style={{ fill: "#ffffff" }} // White color
            d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"
          />
        </svg>
      </div>

      <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        {/* Title Animation */}
        <motion.div
          ref={titleRef}
          className="text-center mb-10 lg:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-4xl font-bold leading-none tracking-tight text-white mb-8 sm:text-4xl"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <span className="relative inline-block">
              <span className="relative">{title}</span>
            </span>
          </h2>
        </motion.div>

        {/* Image and Description Animation */}
        <motion.div
          ref={contentRef}
          className="flex flex-col lg:flex-row items-center lg:justify-center gap-12" // Increased gap
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: contentInView ? 1 : 0,
            y: contentInView ? 0 : 20,
          }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <div className="w-full max-w-xs lg:max-w-md flex justify-center mb-6 lg:mb-0">
            {" "}
            {/* Added bottom margin for spacing */}
            <img
              src={Picture} // Use the imported image
              alt="About the game"
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 text-white text-xl">
            {" "}
            {/* Increased font size to text-xl */}
            <div className="flex flex-col items-center lg:items-start">
              {texts.map((text, index) => (
                <p key={index} className="mb-4 text-center lg:text-left">
                  {text}
                </p>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default GameUnity;
