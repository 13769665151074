import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import contactMeList from "../../../utils/Portfolio/contactMeList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector"; // Ensure you have this utility

const ContactCard = ({ link, icon, title, description, delay, language }) => {
  const { ref: cardRef, inView: cardInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleClick = (e) => {
    const isResume = title === "Resume" || title === "CV"; // Check for both English and Polish titles
    if (isResume) {
      e.preventDefault();

      // First prompt - ask if the user wants to download the resume
      const confirmMessage =
        language === "pl"
          ? "Czy chcesz pobrać CV?"
          : "Do you want to download the resume?";
      const confirmDownload = window.confirm(confirmMessage);

      if (confirmDownload) {
        // Second prompt - ask for language version
        const languagePrompt = window.prompt(
          language === "pl"
            ? "Wybierz wersję: 'PL' dla polskiej, 'EN' dla angielskiej"
            : "Choose version: 'PL' for Polish, 'EN' for English"
        );

        // Handle the user selection for resume version
        const selectedLanguage = languagePrompt && languagePrompt.toLowerCase();

        if (selectedLanguage === "pl") {
          window.location.href = contactMeList.misc.resume.links.pl; // Load Polish version
        } else if (selectedLanguage === "en") {
          window.location.href = contactMeList.misc.resume.links.en; // Load English version
        } else {
          // Handle invalid input or cancel
          alert(
            language === "pl"
              ? "Niepoprawny wybór. Anulowano pobieranie."
              : "Invalid selection. Download canceled."
          );
        }
      }
    }
  };

  return (
    <motion.a
      href={link}
      target="_blank"
      rel="noreferrer"
      aria-label={title}
      ref={cardRef}
      className="group"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: cardInView ? 1 : 0, y: cardInView ? 0 : 20 }}
      transition={{ duration: 0.6, delay }}
      onClick={handleClick} // Add click handler
    >
      <div
        className="relative p-6 bg-gray-100 border-2 border-gray-300 rounded-lg shadow-md"
        style={{
          width: "300px",
          backgroundColor: "#f5f5f5",
          transition: "background-color 0.3s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#e0e0e0")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = "#f5f5f5")
        }
      >
        <div className="flex items-center mb-4 lg:flex-row lg:items-center lg:mb-0">
          <div className="flex items-center justify-center mr-4">
            <img src={icon} alt={title} className="w-12 h-12" />
          </div>
          <h6
            className="font-semibold text-xl leading-6 text-center lg:text-start"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </h6>
        </div>
        <p
          className="text-lg text-gray-800 text-center lg:text-start mt-4 lg:mt-6"
          style={{ fontFamily: "Poppins, sans-serif", lineHeight: "1.75" }}
        >
          {description}
        </p>
      </div>
    </motion.a>
  );
};

const ContactMe = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [language, setLanguage] = useState("en"); // Default to English

  useEffect(() => {
    const langCode = getUserLanguage();
    if (contactMeList.title[langCode]) {
      setLanguage(langCode);
    } else {
      setLanguage("en"); // Fallback to English if language not supported
    }
  }, []);

  const { title, contacts, misc } = contactMeList;
  const currentContacts = contacts[language];

  return (
    <div
      id="contactme"
      className="relative px-4 py-16 mx-auto sm:px-6 md:px-12 lg:px-16 lg:py-20"
    >
      <div className="absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-gray-100 to-gray-200 opacity-90 shadow-md z-10"></div>
      <motion.div
        ref={sectionRef}
        className="relative max-w-5xl mx-auto pt-16 pb-12 lg:pt-24 lg:pb-20"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 20 }}
        transition={{ duration: 0.6 }}
      >
        <div className="text-center mb-12">
          <h2
            className="text-4xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl"
            style={{
              fontFamily: "Poppins, sans-serif",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            <span className="relative inline-block">
              <span className="relative" style={{ color: "#1E2A38" }}>
                {title[language]}
              </span>
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap justify-center gap-8">
          {Object.keys(currentContacts).map((key, index) => {
            const { title, links, icon } = misc[key];
            const description = currentContacts[key].description;
            const link = links ? links[language] : misc[key].link; // Use language-specific link

            return (
              <ContactCard
                key={key}
                link={link}
                icon={icon}
                title={typeof title === "string" ? title : title[language]} // Handle language-specific title
                description={description}
                delay={index * 0.2}
                language={language} // Pass the language prop
              />
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default ContactMe;
