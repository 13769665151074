// utils/projectsList.js
import Santes from "../../img/santes.webp";
import HomeBudget from "../../img/home_budget_icon.webp";
import MonkeyZooManager from "../../img/monkey_zoo_manager.webp";
import SortVisualization from "../../img/sort_visualization.webp";
import OldPortfolio from "../../img/old_portfolio.webp";
import WeatherApp from "../../img/weatherapp.webp";

// Common project data
const commonProjects = {
  Santes: {
    image: Santes,
    technologies: [
      "Godot Engine",
      "GDScript",
      "C++",
      "Java",
      "Kotlin",
      "Android Studio",
      "JSON",
      "Google Play",
      "Google Play Billing",
      "AdMob",
    ],
    googlePlay: "",
    github: "",
    live: "",
  },
  HomeBudget: {
    image: HomeBudget,
    technologies: [
      "Django",
      "Python",
      "JavaScript",
      "HTML",
      "CSS",
      "PostgreSQL",
      "Docker",
      "OAuth2",
      "SMTP",
      "Sphinx",
      "Vercel",
    ],
    github: "https://github.com/woobe-studio/HOME-BUDGET-WEB",
    live: "https://home-budget-web-sigma.vercel.app/",
    demoAccount: {
      username: "demotest",
      password: "Demo12345!",
    },
  },
  MonkeyZooManager: {
    image: MonkeyZooManager,
    technologies: ["C++", "Visual Studio", "Qt", "JSON"],
    github: "https://github.com/woobe-studio/MonkeyZooManager",
    live: "",
  },
  SortVisualization: {
    image: SortVisualization,
    technologies: ["Dart", "Flutter", "Firebase"],
    github: "https://github.com/woobe-studio/real_time_sort_visualization",
    live: "https://sort-visualization-c0955.firebaseapp.com/",
  },
  OldPortfolio: {
    image: OldPortfolio,
    technologies: ["HTML", "CSS", "JavaScript"],
    github: "https://gitlab.com/woobe-studio/old-portfolio",
    live: "https://old-portfolio-woobe-studio-07463121e7374fe9f0044b2b35f4f65e6a03.gitlab.io/",
  },
  WeatherApp: {
    image: WeatherApp,
    technologies: [
      "Kotlin",
      "Android Studio",
      "Firebase",
      "APIs",
      "OAuth2",
      "SMTP",
    ],
    github: "https://github.com/woobe-studio/WeatherApp",
    live: "",
  },
};

// Projects list for English
const projectsListEn = [
  {
    title: "Santes",
    subTitle: "Android Mobile Game",
    description: `Over the course of 2.5 years, I designed and built Santes, an Android mobile game, using the Godot Engine. This experience honed my GDScript skills and expanded my knowledge of game development, from concept through to implementation. Additionally, I integrated features such as AdMob, Google Play Billing, and cloud saving to enhance the user experience.`,
    ...commonProjects.Santes,
  },
  {
    title: "Home Budget Web",
    subTitle: "Fund Management",
    description: `This college project offers a web-based solution for managing funds and accounts, including shared group wallets. Features include user registration (with social login), profile and wallet management, an admin panel, and financial charts for data visualization. It also supports password recovery via SMTP for secure email resets.`,
    ...commonProjects.HomeBudget,
  },
  {
    title: "WeatherApp",
    subTitle: "Weather Mobile App",
    description: `IN PROGRESS. The WeatherApp application is an advanced mobile platform that allows users to track the weather in real time. It offers integration with GPS, OpenWeatherMap API, and Firebase. The app supports multiple languages, ensures cloud data synchronization, and provides advanced features for managing users and their data.`,
    ...commonProjects.WeatherApp,
  },
  {
    title: "Monkey Zoo Manager",
    subTitle: "Zoo Management",
    description: `This college project is a comprehensive zoo monkey management system featuring secure login and registration. Users can manage multiple zoos, habitats, and individual monkeys, with options to add, remove, or update records. It tracks detailed monkey statistics and supports data serialization for persistent storage and retrieval.`,
    ...commonProjects.MonkeyZooManager,
  },
  {
    title: "Real Time Sort Visualization",
    subTitle: "Sorting Algorithms",
    description: `This project visualizes the performance of various sorting algorithms, including five with O(n log n) complexity. It tracks execution time, allows for adjustable delay duration, and lets users change the number of elements to sort, providing an interactive and educational experience.`,
    ...commonProjects.SortVisualization,
  },
  {
    title: "Old Portfolio",
    subTitle: "Web Project",
    description: `This web project showcases my previous work and includes both English and Polish versions. It features handling for incorrect URLs, animations, and a responsive design with flexible layouts for both mobile and desktop views.`,
    ...commonProjects.OldPortfolio,
  },
];

// Projects list for Polish
const projectsListPl = [
  {
    title: "Santes",
    subTitle: "Gra Mobilna na Androida",
    description: `Przez 2,5 roku projektowałem i tworzyłem grę mobilną na Androida o nazwie Santes, korzystając z silnika Godot. To doświadczenie pozwoliło mi udoskonalić umiejętności w GDScript oraz poszerzyć wiedzę na temat tworzenia gier, od koncepcji po wdrożenie. Dodatkowo zintegrowałem funkcje takie jak AdMob, Google Play Billing oraz zapisywanie w chmurze, aby poprawić wrażenia użytkowników.`,
    ...commonProjects.Santes,
  },
  {
    title: "Home Budget Web",
    subTitle: "Zarządzanie Funduszami",
    description: `Ten projekt studencki oferuje rozwiązanie webowe do zarządzania funduszami i kontami, w tym wspólnymi portfelami grupowymi. Funkcje obejmują rejestrację użytkowników (w tym logowanie przez media społecznościowe), zarządzanie profilem i portfelem, panel administracyjny oraz wykresy finansowe do wizualizacji danych. Projekt wspiera także odzyskiwanie hasła za pomocą SMTP, umożliwiając bezpieczne resetowanie przez e-mail.`,
    ...commonProjects.HomeBudget,
  },
  {
    title: "WeatherApp",
    subTitle: "Mobilna Aplikacja Pogodowa",
    description: `W TRAKCIE REALIZACJI. Aplikacja WeatherApp to zaawansowana platforma mobilna, która umożliwia użytkownikom śledzenie pogody w czasie rzeczywistym. Oferuje integrację z GPS, API OpenWeatherMap oraz Firebase. Aplikacja obsługuje wiele języków, zapewnia synchronizację danych w chmurze i oferuje zaawansowane funkcje do zarządzania użytkownikami oraz ich danymi.`,
    ...commonProjects.WeatherApp,
  },
  {
    title: "Monkey Zoo Manager",
    subTitle: "Zarządzanie ZOO",
    description: `Ten projekt studencki to kompleksowy system zarządzania małpami w zoo, oferujący bezpieczne logowanie i rejestrację. Użytkownicy mogą zarządzać wieloma zoo, siedliskami oraz poszczególnymi małpami, z opcjami dodawania, usuwania lub aktualizacji rekordów. System śledzi szczegółowe statystyki dotyczące małp i wspiera serializację danych, umożliwiając trwałe przechowywanie i odzyskiwanie informacji.`,
    ...commonProjects.MonkeyZooManager,
  },
  {
    title: "Wizualizacja Sortowania w Czasie Rzeczywistym",
    subTitle: "Algorytmy Sortowania",
    description: `Ten projekt wizualizuje wydajność różnych algorytmów sortowania, w tym pięciu o złożoności O(n log n). Śledzi czas wykonania, umożliwia dostosowanie czasu opóźnienia oraz pozwala użytkownikom zmieniać liczbę sortowanych elementów, oferując interaktywne i edukacyjne doświadczenie.`,
    ...commonProjects.SortVisualization,
  },
  {
    title: "Wcześniejsze Portfolio",
    subTitle: "Projekt Webowy",
    description: `Ten projekt webowy prezentuje moje wcześniejsze prace i dostępny jest w wersji angielskiej oraz polskiej. Oferuje obsługę błędnych adresów URL, animacje oraz responsywny design z elastycznymi układami zarówno dla urządzeń mobilnych, jak i komputerów stacjonarnych.`,
    ...commonProjects.OldPortfolio,
  },
];
// Full projects list
const projectsList = {
  en: {
    title: "Projects",
    technologyTitle: "Technology",
    sourceTitle: "Source Code",
    liveTitle: "Live Demo",
    projects: projectsListEn,
  },
  pl: {
    title: "Projekty",
    technologyTitle: "Technologia",
    sourceTitle: "Kod Źródłowy",
    liveTitle: "Demonstracja na żywo",
    projects: projectsListPl,
  },
};

export default projectsList;
