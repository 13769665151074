const appTranslations = {
  en: {
    newTitle: "👋 Hey!",
    originalTitle: "Woobe-Studio",
  },
  pl: {
    newTitle: "👋 Hej!",
    originalTitle: "Woobe-Studio",
  },
};

export default appTranslations;
