// utils/aboutMeList.js
const aboutMeList = {
  title: {
    en: "About Me",
    pl: "O Mnie",
  },
  texts: {
    en: [
      "I am currently in my third year of Computer Science at Białystok University of Technology. My primary programming language is Python, and for the creation of Santes – a 2D pixel-art top-down shooter for mobile systems – I used GDScript, which is similar to Python. While working on this project, I edited and optimized the Godot engine code in C++ to improve performance.",
      "I also founded the student group “Data Pigeons” at Białystok University of Technology, which focuses on data analysis and visualization using Python/R. Additionally, I have experience in creating web applications using Django and Python. I am leading the “Świerk 2.0” project at my university with a team of 15 people, ensuring timely and efficient task completion. In this project, I use Jira and Confluence to manage team tasks.",
      "In my free time, I enjoy biking, running, and working out. I also like exploring the Fallout series, experimenting with healthy food options, and diving into small DIY projects.",
    ],
    pl: [
      "Obecnie jestem na trzecim roku Informatyki na Politechnice Białostockiej. Moim głównym językiem programowania jest Python, a do stworzenia Santes – 2D strzelanki w stylu pixel art z widokiem z góry na systemy mobilne – używałem GDScript, który jest podobny do Pythona. Podczas pracy nad tym projektem edytowałem i optymalizowałem kod silnika Godot w C++, aby poprawić jego wydajność.",
      "Założyłem także grupę studencką „Data Pigeons” na Politechnice Białostockiej, która koncentruje się na analizie danych i wizualizacji przy użyciu Pythona/R. Dodatkowo mam doświadczenie w tworzeniu aplikacji webowych przy użyciu Django i Pythona. Prowadzę projekt „Świerk 2.0” na uczelni z zespołem 15 osób, dbając o terminowe i efektywne realizowanie zadań. W ramach tego projektu korzystam z narzędzi Jira i Confluence do zarządzania pracą zespołu.",
      "W wolnym czasie lubię jeździć na rowerze, biegać i ćwiczyć. Lubię także odkrywać serię Fallout, eksperymentować z zdrowymi opcjami żywieniowymi i zagłębiać się w małe projekty DIY.",
    ],
  },
};

export default aboutMeList;
