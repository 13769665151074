const gameDevList = {
  en: {
    title: "Want to know more about my game development?",
    description:
      "I am passionate about game development, and I'd love to share more details with you.",
    buttonText: "Learn More",
  },
  pl: {
    title: "Chcesz dowiedzieć się więcej o moim rozwoju gier?",
    description:
      "Jestem pasjonatem tworzenia gier i chciałbym podzielić się z tobą dodatkowymi szczegółami.",
    buttonText: "Dowiedz się więcej",
  },
};

export default gameDevList;
