// utils/aboutMeList.js
const aboutUnityList = {
  title: {
    en: "About The Game",
    pl: "O Grze",
  },
  texts: {
    en: [
      "I started developing a Western shooter game in Unity back in 2020, dedicating six months to the project. The game features a variety of mechanics, including a health bar, interactive buildings, and shooting with a revolver and shotgun. Players can manage their ammunition with a dynamic ammo bar, and the game offers joystick and button controls for enhanced gameplay.",
      "Throughout the development, I used C# to create engaging mechanics, combining shooting action with strategic management of resources like ammo and health. The use of C# allowed me to implement complex gameplay systems and smooth interactions, ensuring a fun and immersive player experience.",
    ],
    pl: [
      "Zacząłem rozwijać grę westernową w Unity w 2020 roku, poświęcając na ten projekt sześć miesięcy. Gra oferuje szereg mechanik, w tym pasek życia, interaktywne budynki oraz strzelanie z rewolweru i strzelby. Gracze mogą zarządzać swoją amunicją za pomocą dynamicznego paska amunicji, a gra obsługuje kontrolery joysticka i przyciski, co wzbogaca rozgrywkę.",
      "W trakcie rozwoju gry wykorzystałem C# do tworzenia angażujących mechanik, łącząc akcję strzelania z strategicznym zarządzaniem zasobami, takimi jak amunicja i życie. Użycie C# pozwoliło mi zaimplementować skomplikowane systemy rozgrywki oraz płynne interakcje, zapewniając wciągające i satysfakcjonujące doświadczenie gracza.",
    ],
  },
};

export default aboutUnityList;
