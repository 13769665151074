import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import unityDevList from "../../../utils/GameDev/UnityLinkList"; // Import the content file

const UnityGameDevLink = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const content = unityDevList[language] || unityDevList.en; // Fallback to English if language not found

  const handleButtonClick = () => {
    window.location.href = "/gamedev/unity"; // Navigate to external site
  };

  return (
    <div
      id="gamedevlink"
      className="relative flex justify-center items-center py-16 bg-white"
    >
      <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        {/* Title Animation */}
        <motion.div
          ref={titleRef}
          className="text-center mb-10 lg:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-4xl font-bold leading-none tracking-tight text-gray-900 mb-8 sm:text-4xl"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <span className="relative inline-block">
              <span className="relative">{content.title}</span>
            </span>
          </h2>
        </motion.div>

        {/* Button Section */}
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <p className="text-gray-700 text-xl mb-6 text-center">
            {content.description}
          </p>
          {/* Bigger Button Style */}
          <button
            className="flex items-center justify-center h-16 px-8 py-4 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-accent-400 hover:bg-blue-accent-700 text-xl"
            onClick={handleButtonClick} // Attach the onClick handler
          >
            {content.buttonText}
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default UnityGameDevLink;
