// utils/experienceList.js
import chmImage from "../../../src/img/chm.webp"; // Adjust path accordingly

const experienceList = {
  title: {
    en: "Profesional Experience",
    pl: "Profesjonalne Doświadczenie",
    // Add more languages here
  },
  experiences: {
    en: [
      {
        title: "IT Department Internship",
        date: "July 1–31, 2022, and September 1–30, 2022",
        description: `Completed an internship in the IT department, where I gained hands-on experience in technical support and software development. I developed an application using Qt to manage duplicate records within a PostgreSQL database, enhancing data accuracy and system efficiency. My role also involved utilizing Python libraries like Pandas and NumPy for various development tasks. Additionally, I performed hardware troubleshooting, software installations, and ISO installations for computer systems.`,
        image: chmImage,
      },
    ],
    pl: [
      {
        title: "Staż w dziale IT",
        date: "1-31 lipca 2022 r. i 1-30 września 2022 r.",
        description: `Ukończyłem staż w dziale IT, gdzie zdobyłem praktyczne doświadczenie w wsparciu technicznym i rozwoju oprogramowania. Opracowałem aplikację przy użyciu Qt do zarządzania zduplikowanymi rekordami w bazie danych PostgreSQL, co poprawiło dokładność danych i efektywność systemu. W mojej roli korzystałem również z bibliotek Python, takich jak Pandas i NumPy, do różnych zadań programistycznych. Dodatkowo zajmowałem się diagnostyką sprzętu, instalacjami oprogramowania oraz instalacjami ISO dla systemów komputerowych.`,
        image: chmImage,
      },
    ],
    // Add more languages here
  },
};

export default experienceList;
