const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className="flex items-center justify-center py-6 border-t border-gray-800"
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
      }}
    >
      <p
        className="text-base"
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "1rem",
          color: "#ffffff",
        }}
      >
        © {currentYear} woobe-studio
      </p>
    </div>
  );
};

export default Footer;
